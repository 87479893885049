<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>我的主控台</b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ displayText("download_file.list_title", "資料匯出") }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="showLoading">
      <b-card>
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between col-12">
          <h4 class="col-4 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
            {{ displayText("download_file.list_title", "資料匯出") }}
          </h4>
          <div class="col-2">
            <b-button
              variant="inverse-info"
              @click="fetchDownloadFiles"
              class="float-right"
            >
              <i class="fa fa-refresh fa-lg"></i>
              重新整理
            </b-button>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <b-overlay rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="downloadFiles"
                :fields="fields"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(action)="data">
                  <div class="row">
                    <b-button
                      variant="inverse-info"
                      v-if="
                        checkPermission([consts.MY_EXPORT]) &&
                        data.item.status === 'completed' &&
                        checkDate(data.item.expired_at)
                      "
                      :to="{
                        name: 'MyConsoleDownloadFileGetFile',
                        params: {
                          download_file_id: data.item.id,
                        },
                      }"
                      >{{
                        displayText(
                          "download_file.download_file_button_text",
                          "下載檔案"
                        )
                      }}
                    </b-button>
                    <div class="ml-2">
                      <b-button
                        id="copy-tooltip-target"
                        v-if="data.item.show_password && data.item.password != null"
                        variant="outline-info"
                        v-clipboard:copy="data.item.password"
                        v-b-tooltip.click="
                          displayText(
                            'download_file.copy_success_text',
                            '已複製'
                          )
                        "
                      >
                        {{ data.item.password }}
                      </b-button>
                      <b-button
                        variant="inverse-info"
                        v-if="
                          checkPermission([consts.MY_EXPORT]) &&
                          data.item.status === 'completed' &&
                          !data.item.show_password &&
                          data.item.password &&
                          checkDate(data.item.expired_at)
                        "
                        v-clipboard:copy="data.item.password"
                        v-b-tooltip.click="
                          displayText(
                            'download_file.copy_success_text',
                            '已複製'
                          )
                        "
                        @click="data.item.show_password = true"
                        >{{
                          displayText(
                            "download_file.show_download_file_password_text",
                            "顯示密碼"
                          )
                        }}
                      </b-button>
                    </div>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>

          <CustomPagination
            :currentPage="query.page"
            :totalRows="totalRows"
            :perPage="query.per_page"
            @page-change="handlePageChange"
            @per-page-change="handlePerPageChange"
          />
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import _ from 'lodash'
import { format, isBefore } from "date-fns";
import downloadFileApi from "@/apis/download-file";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import VueClipboard from "vue-clipboard2";
import Vue from "vue";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { paginationMixin } from "@/mixins/pagination";
import { displayText } from "@/utils/dashboardUtils";

Vue.use(VueClipboard);

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      totalRows: 0,
      consts,
      downloadFiles: [],
      showLoading: false,
      intervalId: null,
      initialized: false,
      query: {
        page: 1,
        per_page: 20,
      },
    };
  },
  computed: {
    fields() {
      let table = [
        {
          key: "name",
          label: displayText("download_file.field_name", "檔案名稱"),
        },
        {
          key: "processing_at",
          label: displayText(
            "download_file.field_processing_at",
            "開始處理時間"
          ),
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          key: "completed_at",
          label: displayText(
            "download_file.field_completed_at",
            "處理完成時間"
          ),
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          key: "expired_at",
          label: displayText("download_file.field_expired_at", "檔案過期時間"),
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          key: "status",
          label: displayText("download_file.field_status", "狀態"),
          formatter: (value) => {
            return this.getStatus(value);
          },
        },
        {
          key: "staff_name",
          label: displayText("download_file.field_staff_name", "執行者"),
        },
        {
          key: "created_at",
          label: displayText("download_file.field_created_at", "建立時間"),
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
        },
        {
          key: "action",
          label: "",
        },
      ];
      return table;
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchDownloadFiles();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.fetchDownloadFiles();
    this.initialized = true;
    this.intervalId = setInterval(this.fetchDownloadFiles, 10000);
  },
  methods: {
    displayText,
    checkDate(date) {
      if (isBefore(new Date(), new Date(date))) return true;
      return false;
    },
    getStatus(value) {
      if (value === "failed")
        return displayText("download_file.field_status_failed", "失敗");
      if (value === "completed")
        return displayText("download_file.field_status_completed", "完成");
      if (value === "init")
        return displayText("download_file.field_status_init", "尚未處理");
      if (value === "processing")
        return displayText("download_file.field_status_processing", "處理中");
    },
    async fetchDownloadFiles() {
      this.showLoading = true;
      try {
        const { data } = await downloadFileApi.list({ ...this.query });
        this.totalRows = data.meta.total;
        this.downloadFiles = data.data.map((df) => {
          df.show_password = false;
          return df;
        });
      } catch (e) {
        console.log(e);
      }
      this.showLoading = false;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style>
.table-responsive {
  min-height: 300px;
}
</style>
